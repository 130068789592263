import React from "react";
import DataTable from "./components/DataTable";

function App() {
  return (
    <div>
      <DataTable />
    </div>
  );
}

export default App;
